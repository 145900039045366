import React from 'react';
import { graphql, Link, withPrefix } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Layout, Header, Wrapper } from 'components';
import { kebabCase } from 'lodash';

const Grid = styled.div`
  width: 100%;
  color: #fff;
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  box-sizing: border-box;
  padding: 2rem 1.25rem;

  .grid--block-link {
    color: black;
    box-sizing: border-box;
    border-radius: 3px;
    // border: 1px solid $warm-grey;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;
    display: block;
  }

  .grid--block-image {
    margin: 0;
    width: 100%;
    height: auto;
    display: block;
  }

  .grid--block-details {
    display: flex;
    flex-direction: row;
    height: 4rem;
    border-top: 1px solid $white;
  }

  .grid--block-logo {
    height: 100%;
    max-width: 80px;
    display: block;
  }

  .grid--block-text {
    background: $white;
    color: $black;
    flex-grow: 1;
    overflow: hidden;
    padding: 1.2em 0.6em;
  }

  .grid--block-title {
    font-weight: 200;
    white-space: nowrap;
    font-size: 1em;
    padding: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const IndexPage = ({
  data: {
    allMarkdownRemark: { group },
  },
}) => (
  <Layout>
    <Header />
    <Grid>
      {group.map(brands => (
        <Link className="grid--block-link" key={brands.fieldValue} to={`/${kebabCase(brands.fieldValue)}`}>
          <img
            className="grid--block-image"
            src={withPrefix(`/brands/${kebabCase(brands.fieldValue)}-img@2x.jpg`)}
            alt={brands.fieldValue}
          />
          <div className="grid--block-details">
            <img
              className="grid--block-logo"
              alt={brands.fieldValue}
              src={withPrefix(`/brands/${kebabCase(brands.fieldValue)}-logo@2x.png`)}
            />
            <div className="grid--block-text">
              <h2 className="grid--block-title">{brands.fieldValue}</h2>
            </div>
          </div>
        </Link>
      ))}
    </Grid>
  </Layout>
);

export default IndexPage;

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

export const indexPageQuery = graphql`
  query IndexPageQuery {
    allMarkdownRemark(sort: { fields: [frontmatter___brand], order: DESC }) {
      group(field: frontmatter___brand) {
        fieldValue
        totalCount
      }
    }
  }
`;
